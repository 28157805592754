import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { ContactFormId } from '../../constants/GlobalDOM'
import useMicroCopy from '../../hooks/globalContent/useMicroCopy'
import useOffices from '../../hooks/globalContent/useOffices'
import useRootLink from '../../hooks/routing/useRootLink'
import HubspotForm from '../HubspotForm'
import SocialMediaLinks from '../SocialMediaLinks'
import OfficeCard from '../_cards/OfficeCard'
import { InternalLink } from '../_elements/InternalLink/InternalLink'
import LayoutContainer from '../_layouts/LayoutContainer'
import Heading2 from '../_typography/Heading2/Heading2'
import { usePageContext } from '../../hooks/globalContent/usePageContext'

import styles from './footer.module.scss'

interface FooterProps {
  toggleBanner: React.Dispatch<React.SetStateAction<boolean>>
  setIsBigBannerVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Footer({
  toggleBanner,
  setIsBigBannerVisible,
}: FooterProps) {
  const footerTitle = useMicroCopy('text.footer_form_header')
  const hubspotfooterFormId = useMicroCopy('text.hubspot_form_id')
  const hubspotfooterFormTarget = useMicroCopy('text.hubspot_target')
  const hubspotfooterFormIdentifyTraits = useMicroCopy(
    'text.hubspot_identify_traits'
  ).split(', ')
  const privacyPolicyUrl = useMicroCopy('link.privacy_policy_url')
  const privacyPolicyAnchorText = useMicroCopy(
    'text.privacy_policy_anchor_text'
  )

 const esgReportUrl = useMicroCopy('link.esg_report_url') != "{link.esg_report_url}" ? useMicroCopy('link.esg_report_url') : "/esg"
  const esgReportAnchorText = useMicroCopy('text.esg_report_anchor_text') != "{text.esg_report_anchor_text}" ? useMicroCopy('text.esg_report_anchor_text') : "ESG-rapport"

  const cookieSettingsAnchorText = useMicroCopy(
    'text.cookie_settings_anchor_text'
  )
  const offices = useOffices()
  const rootLink = useRootLink()

  const pageContext = usePageContext()
  const siteLocale = pageContext?.locale

  const buttonHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toggleBanner(true)
    setIsBigBannerVisible(true)
  }
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
      <footer className={styles.footer} id={ContactFormId}>
        <LayoutContainer>
          <div>
            <Heading2>{footerTitle}</Heading2>
            <div className={styles.footerFormWrapper}>
              <HubspotForm
                formId={hubspotfooterFormId}
                target={hubspotfooterFormTarget}
                identifyTraits={hubspotfooterFormIdentifyTraits}
              />
            </div>
          </div>
          <div className={styles.officesContainer}>
            {offices.map((office) => {
              return (
                <OfficeCard
                  key={office.id}
                  className={styles.office}
                  office={office}
                />
              )
            })}
          </div>
          <div className={styles.cookieFooter}>
            <SocialMediaLinks className={styles.socialMediaLinks} />
            <InternalLink
              className={styles.policyLink}
              to={privacyPolicyUrl}
              segmentdata={{
                anchor_text: privacyPolicyAnchorText,
                position: 'footer',
                destination: 'internal',
                url: `${rootLink}${privacyPolicyUrl}`,
              }}
            >
              {privacyPolicyAnchorText}
            </InternalLink>
            <div className={styles.divider}>|</div>
            <button
              className={styles.policyLink}
              type="submit"
              onClick={(e) => buttonHandler(e)}
            >
              {cookieSettingsAnchorText}
            </button>
            {siteLocale === 'da' && (
              <>
                <div className={styles.divider}>|</div>
                <InternalLink
                  className={styles.policyLink}
                  to={esgReportUrl}
                  segmentdata={{
                    anchor_text: esgReportAnchorText,
                    position: 'footer',
                    destination: 'internal',
                    url: `${rootLink}${esgReportUrl}`,
                  }}
                >
                  {esgReportAnchorText}
                </InternalLink>
              </>
            )}
          </div>
        </LayoutContainer>
      </footer>
    </GoogleReCaptchaProvider>
  )
}
