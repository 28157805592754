import React, { useEffect, useState } from 'react'
import { usePageContext } from '../../hooks/globalContent/usePageContext'
import useScriptLoader from '../../hooks/useScriptLoader'
import { tracking } from '../_functional/Segment/tracking'
import './hubspot-form.scss'
import useMicroCopy from '../../hooks/globalContent/useMicroCopy'

export interface HubspotFormBlockProps {
  formId: string
  target: string
  identifyTraits?: Array<string>
}
const HubspotForm = ({
  formId,
  target,
  identifyTraits,
}: HubspotFormBlockProps) => {
  const scriptSrc = 'https://js-eu1.hsforms.net/forms/embed/v2.js'
  useScriptLoader(scriptSrc)

  const [createdHubspotForm, setCreatedHubspotForm] = useState()
  
  const textHubspotFormBlocked = useMicroCopy('text.hubspotform_blocked')
  const pageContext = usePageContext()
  const siteLocale = pageContext?.locale

  function onSegmentTracking(event: any) {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmit'
    ) {
      const tempArray: Array<any> = event.data.data
      const hubspotFormFields = Object.assign(
        {},
        ...tempArray.map((x) => ({ [x.name]: x.value }))
      )

      const traits = tracking.getTraitsFromHubspotForm(
        hubspotFormFields,
        identifyTraits
      )
      tracking.identify(traits)

      const hsContext = JSON.parse(hubspotFormFields.hs_context)
      if (hsContext) {
        const properties = {
          form_id: hsContext.originalEmbedContext.formId,
          target: hsContext.formTarget,
          fields: hsContext.renderedFieldsIds,
        }
        tracking.track('Form Submitted', properties)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', onSegmentTracking, false)
    return () => {
      window.removeEventListener('message', onSegmentTracking, false)
    }
  }, [])

  useEffect(() => {
    if (tracking.isBrowser() && window.hbspt) {
      window.analytics.ready(function () {
        const createdHubspotForm = window.hbspt.forms.create({
          // embeeded form data from hubspot.
          region: 'eu1',
          portalId: '26282571',
          formId: formId,
          target: '#' + target,
          locale: siteLocale, // maybe nesseary to add costume validation messages based on locale
        })
        if (createdHubspotForm) {
          setCreatedHubspotForm(createdHubspotForm)
        }
      })
    }
  }, [tracking.isBrowser() && window.hbspt])

  return (
    <>
      {!createdHubspotForm ? (
        <p>{textHubspotFormBlocked}</p>
      ) : (
        <div className="hubspotFormWrapper" id={target}></div>
      )}
    </>
  )
}

export default HubspotForm
